import { createSlice } from "@reduxjs/toolkit";

const campSlice = createSlice({
  name: "campaign",
  initialState: {
    list: [],
    channel: [],
    phoneNo: [],
    messageTemplates: [],
    campaignData: "",
    allCampaignData: [],
    selectedPhoneNo: "+911234567890",
    selectedChannel: null,
    selectedList: null,
    enteredCampaignName: null,
    selectedMessageTemplate: null,
    allContactslistId: null,
  },
  reducers: {
    getlistData: (state, action) => {
      state.list = action.payload.response;
      state.allContactslistId = state.list.map((list) => {
        return list.listId;
      });
    },
    getChannelData: (state, action) => {
      state.channel = action.payload.response;
    },
    getPhoneNoData: (state, action) => {
      state.phoneNo = action.payload.response.split(",");
    },
    getMessgaeTemplateData: (state, action) => {
      state.messageTemplates = action.payload.response;
    },
    getCampaignData: (state, action) => {
      state.campaignData = action.payload.response;
    },
    getAllCampaignData: (state, action) => {
      state.allCampaignData = action.payload.response;
    },
    setSelectedList: (state, action) => {
      state.selectedList = action.payload.response;
    },
    setSelectedPhoneNo: (state, action) => {
      state.selectedPhoneNo = action.payload.response;
    },
    setSelectedChannel: (state, action) => {
      state.selectedChannel = action.payload.response;
    },
    setEnteredCampaignName: (state, action) => {
      state.enteredCampaignName = action.payload.response;
    },
    setSelectedMessageTemplate: (state, action) => {
      state.selectedMessageTemplate = action.payload.response;
    },
  },
});

export const campActions = campSlice.actions;
export default campSlice;
