import { createSlice } from "@reduxjs/toolkit";
import firebase from "../firebase";
import { message } from "antd";
import { showErrorPopup, showSuccessPopup } from "../common/HelperMethods";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("auth-token"),
    // CONVOMATE isLogin: localStorage.getItem("auth-token") != null,
    isLogin: true,
    // CONVOMATE sender: localStorage.getItem("selected-sender"),
    sender: "+911234567890",
    //senders: localStorage.getItem("senders"),
    senders: "+911234567890",
    // whatsapp: [],
    whatsapp: [{ phoneNumber: +911234567890 }],
    //CONVOMATE isQrLoggedIn: localStorage.getItem("qr-logged-in") != null,
    isQrLoggedIn: true,
    applicationid: localStorage.getItem("applicationid") != null,
  },
  reducers: {
    authenticate: (state, action) => {
      state.token = action.payload.data.token;
      localStorage.setItem("auth-token", action.payload.data.token);
      state.applicationid = action.payload.data.applicationid;
      console.log("state.applicationid" + state.applicationid);
      localStorage.setItem("applicationid", action.payload.data.applicationid);
      // state.sender = action.payload.data.userId;
      // localStorage.setItem("selected-sender", action.payload.data.userId);
      // state.isLogin = true;
    },

    sender: (state, action) => {
      state.sender = action.payload.sender;
      localStorage.setItem("selected-sender", action.payload.sender);
      state.isLogin = true;
    },
    // senders: (state, action) => {
    //   state.senders = action.payload.senders;
    //   state.sender = action.payload.sender;
    //   localStorage.setItem("selected-sender", action.payload.sender);
    //   localStorage.setItem("senders", JSON.stringify(action.payload.senders));
    //   state.isLogin = true;
    // },
    senders: (state, action) => {
      let senders = JSON.parse(action.payload.senders).senders;
      state.senders = senders;
      localStorage.setItem("senders", senders);
      // let conn_status =
      //   JSON.parse(action.payload.senders).status == "connected" ? true : false;
      // state.isQrLoggedIn = conn_status;
      // localStorage.setItem("qr-logged-in", conn_status);
    },
    whatsappLogin: (state, action) => {
      state.whatsapp = [];
      state.whatsapp.push(action.payload.connectionStatus);
    },
    whatsappLogout: (state) => {
      state.whatsapp = [];
    },

    logout: (state) => {
      state.token = "";
      state.sender = "";
      state.senders = "";
      state.isLogin = false;
      state.isQrLoggedIn = false;
      state.applicationid = "";
      localStorage.clear();

      firebase
        .auth()
        .signOut()
        .then(function () {
          // Sign-out successful.
          showSuccessPopup("Logged out successfully");
        })
        .catch(function (error) {
          showErrorPopup(error?.message);

          // An error happened
        });
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice;
